import React from 'react'
import './Migration.css'
import { benefitMigrationCards } from './MigrationData'

export default function BenefitsOfMigration() {
    return (
        <div className='benefit-migra-wrapper mt-5'>
            <h1 className='migration-section-title'>Benefits of Migration</h1>
            <div className='benft-cards-contain'>
                {benefitMigrationCards.map((data, index) => (
                    <div key={index} className={`benefit-crds position-relative`} style={{ background: `linear-gradient(to bottom, ${data.linear})` }}>
                        <div className='bnft-img'>
                            <img src={data.img} alt="" loading='lazy' />
                        </div>
                        <div className='bnft-texts'>
                            <h2>{data.title}</h2>
                            <p>{data.para}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
