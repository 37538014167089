import React from 'react'
import './Earn.css'

export default function FarmMforge() {
    return (

        <>
            <div className="farm-mforge mt-sm-5 mt-3">
                <h1 className='earn-section-title'>How To Farm MFORGE</h1>
                <p className='earn-section-para'>
                    <span className='d-lg-block'>Participate in our LP Staking Program and contribute to the strength and growth of the MetaForge liquidity pool. By staking your MFORGE-</span>
                    <span className='d-lg-block'>BNB LP tokens, you not only earn high APYs but also play a vital role in enhancing liquidity and stability within the ecosystem.</span>
                </p>
                <div className='bottom-right'></div>
            </div>
            <div className='row'>
                <div className="col-2 col-md-1 d-flex flex-column gap-3 d-none d-md-block">
                    <div className='d-flex flex-column align-items-end gap-3'>
                        <div className='red-circles'>1</div>
                        <div className='red-lines'></div>
                    </div>
                    <div className='d-flex flex-column align-items-end gap-3'>
                        <div className='red-circles mt-md-3 mt-0'>2</div>
                        <div className='red-lines'></div>
                    </div>
                    <div className='d-flex flex-column align-items-end gap-3'>
                        <div className='red-circles mt-md-3 mt-0'>3</div>
                        <div className='red-lines'></div>
                    </div>
                    <div className='d-flex flex-column align-items-end gap-3'>
                        <div className='red-circles mt-md-3 mt-0'>4</div>
                        <div className='red-lines'></div>
                    </div>
                    <div className='d-flex flex-column align-items-end gap-3'>
                        <div className='red-circles mt-md-3 mt-0'>5</div>
                    </div>

                </div>

                <div className='col-11 col-md-11 steps-section-2'>
                    <div className='d-flex justify-content-between gap-2 gap-sm-0'>
                        <div className='red-circles_resp d-block d-md-none'><span>1</span></div>
                        <div className=' isemnx'>
                            <h1>Go to PancakeSwap</h1>
                            <p>Access PancakeSwap through your web browser</p>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between gap-2 gap-sm-0'>
                        <div className='red-circles_resp d-block d-md-none'><span>2</span></div>
                        <div className=' isemnx'>
                            <h1>Connect Your Wallet</h1>
                            <p>Connect your wallet to PancakeSwap using MetaMask, Trust Wallet, or WalletConnect.</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between gap-2 gap-sm-0'>
                        <div className='red-circles_resp d-block d-md-none'><span>3</span></div>
                        <div className=' isemnx'>
                            <h1>Provide Liquidity</h1>
                            <p>Add MFORGE tokens and an equivalent value of USDT to the liquidity pool.</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between gap-2 gap-sm-0'>
                        <div className='red-circles_resp d-block d-md-none'><span>4</span></div>
                        <div className=' isemnx'>
                            <h1>Receive LP Tokens</h1>
                            <p>Receive LP tokens representing your share of the liquidity pool.</p>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between gap-2 gap-sm-0'>
                        <div className='red-circles_resp d-block d-md-none'><span>5</span></div>
                        <div className=' isemnx'>
                            <h1>Stake LP Tokens on MagnumMeta</h1>
                            <p>Stake your LP tokens in the MFORGE LP pool on Magnummeta to earn additional MFORGE rewards.</p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
