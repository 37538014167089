import React from 'react'
import './Trade.css'
import { ButtonRound, ButtonWrapper } from '../../components/Buttons'
import { FaArrowRightLong } from 'react-icons/fa6'
// import images from '../../Utils/ImagesCollection'
import swapExchange from '../../assests/Group 427321911.webp'
import swapExg from '../../assests/swapExg.png'


export default function Swap() {
    return (
        <>
            <div className='container d-flex justify-content-center mt-1 mt-sm-5'>
                <div className="row d-flex flex-column-reverse flex-sm-row gap-3 gap-sm-0">
                    <div className='col-12 col-md-6 d-flex flex-column justify-content-center gap-4'>
                        <h1 className='swap-title text-center text-sm-start'>Swap and Add Liquidity on DEX</h1>
                        <p className='swap-para-text text-center text-sm-start'>Unlock the power of decentralized finance by swapping tokens and adding liquidity to automated market makers.</p>
                        <div className="swap-btn-grp">
                            <ButtonWrapper className='rspnswpBtn' style={{ width: '275px' }}>Start Swapping <FaArrowRightLong /> </ButtonWrapper>
                            <ButtonRound className='rspnswpBtn' style={{ width: '251px' }}>Add Liquidity <FaArrowRightLong /> </ButtonRound>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <img src={swapExg} className='' alt="swap Exchange" width={'100%'} loading='lazy' />
                    </div>
                </div>
            </div>
        </>
    )
}
