import React from 'react'
import './Home.css'
// import images from '../../Utils/ImagesCollection'
import { ButtonWrapper } from '../../components/Buttons'
import telegram from '../../assests/telegram.png'
import x from '../../assests/x.png'
import medium from '../../assests/medium.png'



export default function Community() {
  return (
    <>
      <div className="container d-flex flex-column gap-4">
        <h1 className='section-title text-center m-0'>Join Our Community</h1>
        <div className="community-img-box">
          <a target='_blank' rel="noopener noreferrer" href="https://t.me/magnummeta">
            <div className='comm_icon'><img src={telegram} alt="telefram" /></div>
          </a>
          <a target='_blank' rel="noopener noreferrer" href="https://x.com/i/flow/login?redirect_after_login=%2FMagnumMeta">
            <div className='comm_icon'> <img src={x} alt="x" /></div>
          </a>
          <a target='_blank' rel="noopener noreferrer" href="https://medium.com/@magnummeta">
            <div className='comm_icon'> <img src={medium} alt="medium" /></div>
          </a>
        </div>
        <ButtonWrapper style={{ width: '200px', margin: '0 auto' }}>Contact Us</ButtonWrapper>
      </div>
    </>
  )
}
