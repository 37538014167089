import React from 'react'
// import images from '../../../Utils/ImagesCollection'
import './Index.css'
import radioPointIcon from '../../../assests/radioPointIcon.png'
import farmImage from '../../../assests/farmImage.webp'


export default function Farm() {

    const para = [
        {
            text: 'Yield farming with MagnumMeta transcends mere buzzwords; it\'s a strategic asset optimization approach. Our yield farming features empower you to maximize your assets\' potential, earning rewards while bolstering the liquidity and stability of our ecosystem.'
        },
        {
            text: 'MagnumMeta\'s yield farming protocol isn\'t static; it\'s dynamic and adaptable to market trends. Whether you\'re a seasoned DeFi enthusiast or a newcomer, MagnumMeta equips you with the tools and resources to innovate and thrive in the ever-evolving landscape of decentralized finance.'
        }
    ]

    return (
        <>
            <div className='w-100 row d-flex flex-sm-row flex-column-reverse'>
                <div className="frm-cnt-lft col-12 col-lg-6">
                    {
                        para.map((item, index) => (
                            <div key={index} className='d-flex gap-1'>
                                <img style={{ width: '30px', height: '30px' }} src={radioPointIcon} alt="radioPointIcon" loading='lazy' />
                                <p key={index}>{item.text}</p>
                            </div>
                        ))
                    }
                </div>
                <div className="frm-img-rgt col-12 col-lg-6 p-0 mt-sm-1 mt-3 mb-sm-1 mb-5">
                    <img src={farmImage} alt="farmimage" loading='lazy' />
                </div>
            </div>
        </>
    )
}
