import React from 'react'
import './Dao.css'
import { votingPropsCards } from './DaoData'

export default function Voting() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center gap-4 voting_res_bg">
            <h1 className="dao-section-title">Voting and Proposals</h1>
            <div className="w-100 d-flex flex-wrap justify-content-center gap-3 gap-md-0">
                {
                    votingPropsCards.map((data, index) => (
                        <div key={index} className={`voting-prop-card ${index < 2 && 'voting-card-brder'}`}>
                            <div className="eco-card-cntxxx">{data.num}</div>
                            <h3>{data.title}</h3>
                            <p className='px-1'>{data.subtitle}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
