import React, { useState } from 'react'
import './Index.css'
import Farm from './Farm'
import Stack from './Stack'

export default function Index() {
    const [tab, setTab] = useState(true)

    return (
        <>
            <div className="container work_main_container_xxl">
                <div className='work_main-tsc'>
                    <h1 className='hw-it-wrk-tlt'>Empowering Participation</h1>
                    <div className='work-grp-btn'>
                        <button onClick={() => setTab(true)} className={`work_brn1-fns ${tab && 'farm-btn'}`}>
                            Yield Farming
                        </button>
                        <button onClick={() => setTab(false)} className={`work_brn1-fns ${!tab && 'stack-btn'}`}>
                            Staking
                        </button>
                    </div>
                </div>
                {tab ? <Farm /> : <Stack />}
            </div>
        </>
    )
}
