import React from 'react'
import './Earn.css'
import { ButtonRound, ButtonWrapper } from '../../components/Buttons'
import YieldFarming from './YieldFarming'
import FarmMforge from './FarmMforge'
import StakeMforge from './StakeMforge'
import EarnBySharing from './EarnBySharing'

export default function Earn() {

  return (
    <>
      <div className='main-earn-hai'>
        <section className='main-earn-contain'>
          <div className="container d-flex flex-column gap-5">
            <div className="earn-passively mt-sm-5 mt-0 pt-sm-5 pt-4">
              <h1 className='earn-section-title'>
                <span className='d-lg-block'>Earn with yield farming</span>{' '}
                <span className='d-lg-block'>and Staking</span>
              </h1>
              <p className='earn-section-para'>
                <span className='d-lg-block'>Unlock new revenue streams by leveraging the power of decentralized finance (DeFi)</span>
              {' '}  <span className='d-lg-block'>through yield farming and staking.</span>
              </p>
              <div className="d-flex flex-wrap justify-content-center gap-4">
                <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://app.magnummeta.xyz/farming">
                  <ButtonWrapper className='rspnsyf' style={{ width: '267px' }}>Start Yield Farming</ButtonWrapper>
                </a>
                <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
                  <ButtonRound className='rspnsyf' style={{ width: '229px' }}>Documentation</ButtonRound>
                </a>

              </div>
            </div>

            <YieldFarming />
            <FarmMforge />
            <StakeMforge />
            <EarnBySharing />

          </div>
        </section>
      </div>
    </>
  )
}
