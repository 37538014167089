// import images from "../../Utils/ImagesCollection";
import stakeholder from '../../assests/Stakeholder.png'
import decentralized from '../../assests/Decentralized.png'
import commitment from '../../assests/Commitment.png'

import tokenloc from '../../assests/TokenLoc.png'
import voting from '../../assests/VotingPower.png'
import liquidity from '../../assests/LiquidityInc.png'
import flexible from '../../assests/FlexibleWith.png'

import proposal from '../../assests/Proposal.png'
import implementation from '../../assests/Implementation.png'
import transparent from '../../assests/Transparent.png'

export const cardsData = [
    {
        img: stakeholder,
        title: "Stakeholder Empowerment",
        subtitle: "MetaForge holders can actively participate in governance by staking their tokens for a fixed term, gaining voting power proportional to their stake.",
        color: '#0E4E8D',
        bgColor: '#E4F1FF05, #E4F1FF80',
    },
    {
        img: decentralized,
        title: "Decentralized Decisions",
        subtitle: "The community directly shapes the project's direction through a democratic process of proposal submissions and voting.",
        color: '#F69737',
        bgColor: '#FEF1E505, #FEF1E580',
    },
    {
        img: commitment,
        title: "Commitment Rewards",
        subtitle: "Longer staking periods unlock increased voting power, incentivizing long-term involvement and commitment to the DAO.",
        color: '#3FBE7F',
        bgColor: '#DCFFED05, #DCFFED80',
    },
]

export const votingCards = [
    {

        title: 'Project Development Roadmap',
    },
    {

        title: 'Allocation of Treasury Funds',
    },
    {

        title: 'Uses for tokens acquired in buybacks',
    }
]

export const votingCards2 = [
    {

        title: 'Ecosystem Partnerships and Integrations',
    },
    {

        title: 'Community Initiatives and Events',
    },
    {

        title: 'Changes to Governance Processes',
    },
]


export const votingPropsCards = [
    {
        num: 1,
        title: 'Proposal Submission',
        subtitle: 'MetaForge holders submit governance proposals for the community to consider.',
    },
    {
        num: 2,
        title: 'Community Deliberation',
        subtitle: 'The community discusses and debates the proposals, offering feedback and insights.',
    },
    {
        num: 3,
        title: 'Voting Period',
        subtitle: 'Eligible stakeholders cast their votes, with the outcome determined by the will of the community.',
    },
]


export const stakingCardsData = [

    {
        img: tokenloc,
        title: 'Token Locking',
        subtitle: 'MetaForge holders can lock their tokens for a fixed duration, typically a minimum of 3 months.',
    },
    {
        img: voting,
        title: 'Voting Power Boost',
        subtitle: 'The longer the staking period, the greater the voting power allocated to the stakeholder.',
    },
    {
        img: liquidity,
        title: 'Liquidity Incentives',
        subtitle: 'Stakers may receive additional rewards or benefits for locking their tokens for extended periods.',
    },
    {
        img: flexible,
        title: 'Flexible Withdrawal',
        subtitle: 'Stakeholders can withdraw their tokens at any time, though early withdrawal may result in reduced benefits.',
    },

]

export const executionCards = [
    {
        img: proposal,
        title: 'Proposal Approval',
        subtitle: 'Proposals that receive majority support from the community are approved for execution.',
        color: '#FFFAEF, #FFFFFF'
    },
    {
        img: implementation,
        title: 'Implementation',
        subtitle: 'The DAO\'s core team or designated entities implement the approved proposals according to the community\'s mandate.',
        color: '#F6ECFE, #FFFFFF'
    },
    {
        img: transparent,
        title: 'Transparent Reporting',
        subtitle: 'The DAO provides regular updates on the progress and outcomes of the executed proposals, ensuring accountability.',
        color: '#F3FDFF, #FFFFFF'
    },


]