import React from 'react'
import './Migration.css'
import { ButtonRound, ButtonWrapper } from '../../components/Buttons'
import TokenAllocation from './TokenAllocation'
import MigrationRatio from './MigrationRatio'
import BenefitsOfMigration from './BenefitsOfMigration'
import MigrationProcess from './MigrationProcess'

export default function Migration() {

    return (
        <>
            <section className='migra_MainContain'>
                <div className='container-xxl d-flex flex-column align-items-center gap-5'>
                    <div className='migration-wrapper'>
                        <h1 className='migration-section-title'>
                            <span className='d-lg-block'>Migration to New MFORGE</span>
                            <span className='d-lg-block'>Token</span>
                        </h1>
                        <p className='migration-section-para'>
                            <span className='d-lg-block'>Upgrading to the new MFORGE token with improved features and a streamlined</span>
                           {' '} <span className='d-lg-block'>migration process.</span>
                        </p>
                        <div className='d-flex flex-wrap justify-content-center gap-4'>
                            <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
                                <ButtonWrapper style={{ width: '200px' }}>Learn More</ButtonWrapper>
                            </a>
                            <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://app.magnummeta.xyz/migration">
                                <ButtonRound style={{ width: '200px' }}>Get Started</ButtonRound>
                            </a>
                        </div>
                    </div>
                </div>
                <TokenAllocation />
                <MigrationRatio />
                <BenefitsOfMigration />
                <MigrationProcess />
            </section>
        </>
    )
}
