import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { RxCross2, RxHamburgerMenu } from 'react-icons/rx';
import magnumLogo from '../../assests/logo.webp'
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import telegramIcon from '../../assests/telegramIcon.png'
import { ButtonWrapper } from '../Buttons';


function Navbar2() {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate()
  const [togalIcon, setTogalIcon] = useState(true);

  const handleToggle = () => {
    setExpanded(!expanded);
    setTogalIcon(!togalIcon);

  };

  const handleSelect = () => {
    setExpanded(!expanded);
    setTogalIcon((prev) => !prev);
  };

  const handleSelectItem = () => {
    setExpanded((prev) => !prev);
    setTogalIcon(prev => !prev)
  }

  return (
    <>
      <div className='nav-Top'>
        <button className='nav-Top-Button'> <span className=' d-flex align-items-center d-sm-block d-none'> Our only verified <RiVerifiedBadgeFill size={20} /> Telegram handle:</span> Join Telegram Community
          <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://t.me/magnummeta"><img src={telegramIcon} alt="" width={20} /></a>
        </button>
      </div>
      <div className='navbar_main container-xxl'>
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary nav-bar-typo" expanded={expanded}>
          <Container className='px-sm-1 px-0'>
            <Navbar.Brand as={Link} to="/home" onClick={handleSelect}>
              <img src={magnumLogo} alt="magnum logo" width={'220px'} loading='lazy' />
            </Navbar.Brand>
            <Navbar.Toggle onClick={handleToggle} aria-controls="responsive-navbar-nav">
              {togalIcon ? <RxHamburgerMenu className='abcxyz' fontSize={35} color='#E84427' /> : <RxCross2 className='abcxyz' color='#E84427' fontSize={40} />}
            </Navbar.Toggle>
            <Navbar.Collapse className="justify-content-evenly x" id="responsive-navbar-nav" style={{ maxHeight: '230px' }} navbarScroll>
              <Nav className="mx-auto navbar-nav my-2 my-lg-0 nav-links nav-items-head" onSelect={handleSelect}>
                <Nav.Link to="/trade" className="nav-link " onClick={handleSelectItem}>Trade</Nav.Link>
                <Nav.Link to="/earn" className="nav-link " onClick={handleSelectItem}>Earn</Nav.Link>
                <Nav.Link to="/dao" className="nav-link " onClick={handleSelectItem}>Dao</Nav.Link>
                <Nav.Link to="/migration" className="nav-link " onClick={handleSelectItem}>Migration</Nav.Link>
              </Nav>
              <ButtonWrapper className='lunch-app' style={{ width: '193px', height: '50px' }}>
                <a target='_blank' rel="noopener noreferrer" href="https://app.magnummeta.xyz/home">Launch App</a>
              </ButtonWrapper>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
export default Navbar2;
