import React from 'react'
import './Dao.css'
import { cardsData } from './DaoData'

export default function MagnumGov() {
    return (
        <div className="magnum-wrapper">
            <h1 className='dao-section-title'>MagnumMeta Governance</h1>
            <div className="d-flex flex-wrap justify-content-center gap-5">
                {
                    cardsData.map((data, index) => (
                        <div key={index} className="magnum-cards" style={{ borderBottom: `10px solid ${data.color}`, background: `linear-gradient(to bottom, ${data.bgColor})` }}>
                            <img src={data.img} alt="" width={100} className='mb-2' loading='lazy' />
                            <h4 className=''>{data.title}</h4>
                            <p className='dao-section-para'>{data.subtitle}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
