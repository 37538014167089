// import images from '../../Utils/ImagesCollection';
import improvedTech from '../../assests/improvedTech.png'
import increasedLiq from '../../assests/increasedLiq.png'
import strongCom from '../../assests/strongerCom.png'

import conntWallet from '../../assests/conntWallet.png'
import verifyTokens from '../../assests/verifyTokens.png'
import claimMforge from '../../assests/claimMforge.png'


export const tokenCards = [
    {
        title: 'Migration: 100,000,000',
        para: 'Fully migrating existing MGB holders to the new MFORGE tokens.',
    },
    {
        title: 'Ecosystem: 10,000,000',
        para: 'Additional tokens reserved for community initiatives and rewards.',
    }
]

export const migrationCards = [
    {

        num: 1,
        title: '1:5 Ratio',
        para: 'Each new MFORGE token is equivalent to 5 old MGB tokens.',

    },
    {

        num: 2,
        title: 'Simplified Conversion',
        para: 'The straightforward 1:5 ratio makes the migration process easy and transparent.',

    },
    {

        num: 3,
        title: 'Equitable Transition',
        para: 'The ratio ensures fair representation of existing MGB holders in the new MFORGE ecosystem.',
    }
]

export const benefitMigrationCards = [

    {
        img: improvedTech,
        title: 'Improved Technology',
        para: 'The new MFORGE token leverages the latest blockchain advancements for enhanced security and functionality.',
        linear: '#FFF9ED, #FFFFFF',
    },
    {
        img: increasedLiq,
        title: 'Increased Liquidity',
        para: 'The migration ensures ample liquidity for the MFORGE token, enabling efficient trading and market growth.',
        linear: '#FFF2FA, #FFFFFF',
    },
    {
        img: strongCom,
        title: 'Stronger Community',
        para: 'The migration unites the existing MGB community under the new MFORGE brand, fostering a more engaged and vibrant ecosystem.',
        linear: '#F1FCFF, #FFFFFF',
    }

]

export const processCards = [
    {
        num: 1,
        image: conntWallet,
        title: 'Connect Wallet',
        para: 'Link your existing MGB token wallet to the migration platform.',
    },
    {
        num: 2,
        image: verifyTokens,
        title: 'Verify Tokens',
        para: 'Confirm the number of MGB tokens you hold to determine your MFORGE allocation.',
    },
    {
        num: 3,
        image: claimMforge,
        title: 'Claim MFORGE',
        para: 'Securely claim your new MFORGE tokens and add them to your wallet.',
    },

]
