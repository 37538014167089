import React from 'react'
import { DotLoader } from "react-spinners";
import '../App.css'

export default function Loader() {
    return (
        <div className="loader">
            <DotLoader color="#E84427" />
        </div>
    )
}
