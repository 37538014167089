import React from 'react'
import './Home.css'
import images from '../../Utils/ImagesCollection'
import golden_bitcoin from '../../assests/golden_bitcoin.png'
import phoneLock from '../../assests/hand-holding-phone-with-lock-screen-it 1.png'
import bitcoinBusiness from '../../assests/bitcoin-business-as-part-economy 1.png'

export default function News() {

    const dummyCardData = [
        {
            img: golden_bitcoin,
            title: 'Unlocking the Power of Tokens A Beginner\'s Guide',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s .',
            date: 'June 30, 2021'
        },
        {
            img: phoneLock,
            title: 'The Tokenization of Identity Securing Personal Data',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s .',
            date: 'June 30, 2021'
        },
        {
            img: bitcoinBusiness,
            title: 'The Tokenization of Identity: Securing Personal Data',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s .',
            date: 'June 30, 2021'
        },
    ]
    return (
        <>
            <div className="container">
                <h1 className='section-title text-center mb-5'>Latest News</h1>
                <div className='d-flex justify-content-center align-items-center gap-5 flex-wrap'>
                    {dummyCardData.map((data, index) => (
                        <div key={index} className="news_group_cards">
                            <div className="news_card">
                                <img className='newcard_images' src={data.img} alt="golden_bitcoin" loading='lazy' />
                                <h1 className='new_card_title m-0'>{data.title}</h1>
                                <p className='news_card_descr m-0'>{data.description}</p>
                                <div className='news_date news_card_descr'>{data.date}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
