import React from 'react'
import './Migration.css'
import { tokenCards } from './MigrationData'

export default function TokenAllocation() {
    return (
        <div className='token-contain mt-5'>
            <h1 className='migration-section-title'>Token Allocation</h1>
            <div className='d-flex flex-wrap justify-content-center gap-5'>
                {tokenCards.map((item, index) => {
                    return (
                        <div key={index} className='token-cards'>
                            <h4>{item.title}</h4>
                            <p className='migration-cards-para'>{item.para}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
