import React from 'react'
// import images from '../../Utils/ImagesCollection'
import './Home.css'
import { ButtonWrapper } from '../../components/Buttons'
import { FaArrowRightLong } from 'react-icons/fa6'
import home01 from '../../assests/home01.png'
import home02 from '../../assests/home02.png'
import home03 from '../../assests/home03.png'
import home04 from '../../assests/home04.png'
import home05 from '../../assests/home05.png'
import radioPointIcon from '../../assests/radioPointIcon.png'


export default function MugnumDAO() {

    const DAOData = [
        {
            text: 'MagnumMeta operates as a Decentralized Autonomous Organization (DAO), granting token holders decision-making authority within the ecosystem.'
        },
        {
            text: 'MetaForge holders can actively participate in governance by staking tokens for at least three months, accruing more voting power with longer staking durations.'
        },
        {
            text: 'Key decisions, including token usage from buybacks, partnership agreements, development plans, treasury fund allocation, and token burning, are subject to proposals and voting by stakeholders.'
        },
        {
            text: 'Governance token holders can propose improvements and innovations for the platform, with decisions made through transparent voting processes where the proposal with the majority of votes prevails. All staked tokens are locked during voting periods, ensuring fairness and security.'
        }
    ]

    const magnumDaoCards = [

        {
            img: home01,
            title: '1. Uses For Tokens Acquired In Buybacks',
            linear: '#FFF1D6, #FFFFFF',
        },
        {
            img: home02,
            title: '2. Votes Around Partnerships',
            linear: '#EAF3FC, #FFFFFF',
        },
        {
            img: home03,
            title: '3. Development',
            linear: '#FFD3EE, #FFFFFF',
        },
        {
            img: home04,
            title: '4. Use of Treasury Funds',
            linear: '#EEDAFF, #FFFFFF',
        },
        {
            img: home05,
            title: '5. Token Burn',
            linear: '#E6F8FC, #FFFFFF',
        }

    ]

    return (
        <>
            <div className='magnumDao_bgi'>
                <div className="container">
                    <div className="row">
                        <h1 className='section-title text-center mb-sm-5 mb-4'>MagnumMeta DAO</h1>
                        <div className='d-flex justify-content-around gap-4 flex-column-reverse flex-sm-row flex-wrap'>
                            <div className="mgnum-dao-content col-12 col-lg-6">
                                {
                                    DAOData.map((item, index) => (
                                        <div key={index} className='d-flex gap-2 mb-4'>
                                            <img style={{ width: '30px', height: '30px' }} src={radioPointIcon} alt="radioPointIcon" loading='lazy' />
                                            <p className='m-0 magnum-dao-para' key={index}>{item.text}</p>
                                        </div>
                                    ))
                                }
                                <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
                                <ButtonWrapper className='mgnm-rm-btn'>
                                    Read More <FaArrowRightLong /> 
                                </ButtonWrapper>
                                </a>
                            </div>
                            <div className="mgnum_dao_card d-flex justify-content-center">
                                <div className='w-100 magnumdao-cards-contain'>
                                    {magnumDaoCards.map((data, index) => (
                                        <div key={index} className={`magnumDao-crds position-relative`} style={{ background: `linear-gradient(to bottom, ${data.linear})` }}>
                                            <div className='manuDa-texts'>
                                                <h2 className='m-0'>{data.title}</h2>
                                            </div>
                                            <div className='manuDa-img'>
                                                <img src={data.img} alt="" loading='lazy' />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
