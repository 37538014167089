import React from 'react'
import './Earn.css'
import stakeMforge from '../../assests/Illustration.png'
import { FaArrowRightLong } from 'react-icons/fa6'
import { ButtonWrapper } from '../../components/Buttons'

export default function StakeMforge() {
    return (
        <div className="row mt-5">
            <div className="col-lg-6 d-flex justify-content-center">
                <img src={stakeMforge} alt="" width={'80%'} loading='lazy' />
            </div>
            <div className="stake-col col-lg-6 mt-5">
                <h1 className='earn-section-title text-md-start'>Stake MFORGE</h1>
                <p className='earn-section-para text-start'>Ready to maximize your rewards with Magnummeta's utility token, MFORGE? Stake your MFORGE tokens now and unlock a world of opportunities! By staking MFORGE, you'll earn passive income while contributing to the stability and growth of the Magnummeta ecosystem.</p>
                <p className='earn-section-para text-start'>Get started today and join the MFORGE community in shaping the future of decentralized finance (DeFi) on Magnummeta!</p>

                <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://app.magnummeta.xyz/farming">
                    <ButtonWrapper className='rspnsyf'>Start Staking <FaArrowRightLong /></ButtonWrapper>
                </a>
            </div>
        </div>
    )
}
