import React from 'react'
// import images from '../../Utils/ImagesCollection'
import eLearn from '../../assests/learning.png'
import aiIcon from '../../assests/aiIcon.png'
import mgnumNote from '../../assests/MgnumNote.png'
import './Home.css'

export default function MagnumEcosystem() {

    const ecoSystemCardData = [
        {
            number: 1,
            title: 'E-Learning',
            para: 'Discover a personalized approach to learning through our peer-to-peer tutoring marketplace. Here, learners connect with specialized tutors, scheduling tailored sessions to tackle challenging subjects. By fostering a collaborative environment, learners receive individualized support, enhancing their comprehension and mastery of complex topics.',
            imageurl: eLearn
        },
        {
            number: 2,
            title: 'Al LMS',
            para: 'Experience the future of education with MagnumMeta\'s AI Learning Management System (LMS). Combining the power of artificial intelligence and blockchain technology, our AI LMS represents a paradigm shift in online learning. With a suite of advanced features, meticulously crafted to cater to diverse learning needs, MagnumMeta AI LMS redefines the learning experience on a global scale.',
            imageurl: aiIcon
        },
        {
            number: 3,
            title: 'MagnumX',
            para: 'Enter a new era of education with MagnumX – a transformative ecosystem uniting tutors, creators, organizations, and institutions. MagnumX revolutionizes educational delivery, fostering collaboration and innovation. Designed to unlock the full potential of learners worldwide, MagnumX empowers individuals and organizations to thrive in the dynamic landscape of education.',
            imageurl: mgnumNote
        },
    ]

    return (
        <>
            <div className="container ecobgcls">
                <div className="bottom-left"></div>
                <h1 className='section-title mt-3 mb-5 text-center'>MagnumMeta Ecosystem</h1>
                <div className="eco-card-main">
                    {
                        ecoSystemCardData.map((data, index) => (
                            <div key={index} className={`eco-card-size ${index <= 1 || index > ecoSystemCardData.length ? '' : 'border-0'}`}>
                                <div className='d-flex align-items-center gap-3 justify-content-between'>
                                    <div className='mgnm-card-div d-flex flex-wrap gap-3 align-items-center justify-content-start'>
                                        <img src={data.imageurl} alt="" loading='lazy' />
                                        <h1 className='eco-h1-title'>{data.title}</h1>
                                    </div>
                                    <span className='card_indexNum'>0{index + 1}</span>
                                </div>
                                <p className='my-2 eco-card-para'>
                                    {data.para}
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
