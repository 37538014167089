import styled from "styled-components";

const buttonStyles = `
  width: 270px;
  height: 54px;
  font-size: 18px;
  display: flex;
  gap: 1.2rem;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 38px;
  border: 3px solid transparent;
`;

export const ButtonWrapper = styled.div`
  ${buttonStyles}
  background: linear-gradient(90deg, #E84427, #E84427, #E84427) padding-box, linear-gradient(180deg, #ED1C24 0%, #FFFFFF 100%) border-box;
  color: #fff;
  transition: 0.3s ease-in-out;
  font-weight:700;

  &:hover,
  &:focus {
    color: #000;
    background: #fff;
    border: 3px solid #E84427;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    cursor:pointer;
  }
`;

export const ButtonRound = styled.div`
  ${buttonStyles}
  background: linear-gradient(90deg, #fff, #fff, #fff) padding-box, linear-gradient(180deg, #ED1C24 100%, #FFFFFF 100%) border-box;
  color: #000;
  font-weight: 700;
  width: 270px;
  transition: 0.4s ease-in-out;
  text-align:center;

  &:hover,
  &:focus {
    color: #fff;
    background: linear-gradient(to right, #AC8642, #E5C06B, #B18B46);
    border: none;
    outline: none;
    font-weight: 600;
    transition: 0.3s ease-in-out;
    cursor:pointer;
  }

`;
