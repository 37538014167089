import React from 'react'
import './Dao.css'
import { stakingCardsData } from './DaoData'

export default function StakingMechanics() {
    return (
        <div className="staking-section">
            <h1 className='dao-section-title'>Staking Mechanics</h1>
            <div className="staking-cards-con">
                {
                    stakingCardsData.map((data, index) => (
                        <div key={index} className="staking-cards position-relative">
                            <img src={data.img} className="position-absolute staking-img" alt='staking mechanism' width={100} loading='lazy' />
                            <h3>{data.title}</h3>
                            <p>{data.subtitle}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
