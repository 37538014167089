import React from 'react'
import './Migration.css'
import { processCards } from './MigrationData'
import dottedLine from '../../assests/dottedLine.png'

export default function MigrationProcess() {
    return (
        <>
            <div className='position-relative mt-5 migration_prcs'>
                <div className='bg-borderDotted'>
                    <img src={dottedLine} alt="" width={'95%'} loading='lazy' />
                </div>
                <div className='migration-procses'>
                    <div className='bottomMigra-left'></div>
                    <h1 className='migration-section-title'>Migration Process</h1>
                    <div className='procss-wrapper'>
                        {
                            processCards.map((data, index) => (
                                <div key={index} className='procss-cards position-relative'>
                                    <div className='eco-card-cnt procs-num position-absolute'>{data.num}</div>
                                    <img src={data.image} alt="" loading='lazy' />
                                    <h4>{data.title}</h4>
                                    <p>{data.para}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
