import React from 'react'
// import images from '../../../Utils/ImagesCollection'
import './Index.css'
import { ButtonWrapper } from '../../../components/Buttons'
import { FaArrowRightLong } from 'react-icons/fa6'
import 'react-lazy-load-image-component/src/effects/blur.css';
import referralimg from '../../../assests/referralimg.png'


export default function Referal() {

    return (
        <>
            <div className='container'>
                <div className='w-100 row'>
                    <div className="frm-cnt-lft col-12 col-md-6 ">
                        <h1 className='hw-it-wrk-tlt text-start'>MagnumMeta Referral Program unlocks greater rewards</h1>
                        <div className="frm-img-rgt px-3 col-12 col-md-6 p-0 mb-4 d-block d-sm-none">
                            <img src={referralimg} alt="farmimage" loading='lazy' />
                        </div>
                        <p className='stck-p-txt'>
                            Expand your earnings with MagnumMeta's Referral Program. It's simple: share your unique link, invite friends to join, build a thriving community, and watch your rewards multiply. With each successful referral, you'll earn additional rewards, enhancing both your earning potential and the vitality of the MagnumMeta ecosystem. Join us in revolutionizing decentralized learning and earning, one referral at a time.
                        </p>
                        <div className='d-flex justify-content-center justify-content-md-start mb-5'>
                            <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
                                <ButtonWrapper style={{ width: '230px' }}>Read More <FaArrowRightLong /> </ButtonWrapper>
                            </a>
                        </div>
                    </div>
                    <div className="frm-img-rgt px-3 col-12 col-md-6 p-0 mb-4 d-sm-block d-none">
                        <img src={referralimg} alt="farmimage" loading='lazy' />
                    </div>
                </div>
            </div>
        </>
    )
}
