import React from 'react'
import './Migration.css'
import { migrationCards } from './MigrationData'

export default function MigrationRatio() {
    return (
        <div className='migration-ratio'>
            <h1 className='migration-section-title'>Migration Ratio</h1>
            <div className='d-flex flex-wrap justify-content-center gap-4'>
                {migrationCards.map((data, index) => {
                    return (
                        <div key={index} className={`migrationRatio-cards ${index < 2 && 'border-rht'}`}>
                            <div className='eco-card-cnt'>{data.num}</div>
                            <h2>{data.title}</h2>
                            <p>{data.para}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
