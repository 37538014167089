import React from 'react'
import Swap from './Swap'
import LiquidtyonDex from './LiquidtyonDex'

export default function Trade() {
  return (
    <>
      <div>
        <Swap />
        <LiquidtyonDex />
      </div>
    </>
  )
}
