import React from 'react'
import './Earn.css'
import spread from '../../assests/spread.png'
import collabrative from '../../assests/collabrative.png'
import exponential from '../../assests/exponential.png'

export default function EarnBySharing() {

    const earnCardData = [
        {
          img: spread,
          title: 'Spread the Word',
          subtitle: 'Invite your friends and family to join the platform and earn rewards.',
          color: '#0E4E8D'
        },
        {
          img: collabrative,
          title: 'Collaborative Earnings',
          subtitle: 'Earn a percentage of the rewards generated by your referrals activity.',
          color: '#F69737'
        },
        {
          img: exponential,
          title: 'Exponential Growth',
          subtitle: 'As your referral network expands, your earning potential can grow exponentially.',
          color: '#3FBE7F'
        },
    
      ]


    return (
        <div className="earn-sharing d-flex flex-column justify-content-center align-items-center gap-4 mt-5">
            <h1 className='earn-section-title'>Earn by Sharing</h1>
            <div>
                <div className="d-flex flex-wrap justify-content-center gap-4">
                    {
                        earnCardData.map((data, index) => (
                            <div key={index} className="earn-cards" style={{ borderBottom: `4px solid ${data.color}` }} >
                                <img className='mb-2' src={data.img} alt="" loading='lazy' />
                                <h4>{data.title}</h4>
                                <p>{data.subtitle}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
