import magnumLogo from '../assests/magnummetalogo.png'
import magnumForge from '../assests/magnumMetaForge.webp'
import radioPointIcon from '../assests/radioPointIcon.png'
import farmImage from '../assests/farmImage.webp'
import stackImage from '../assests/StackImage.png'
import stackStepper from '../assests/stackStepper.webp'
import magnumDao from '../assests/magnumDao.png'
import eLearn from '../assests/learning.png'
import aiIcon from '../assests/aiIcon.png'
import mgnumNote from '../assests/MgnumNote.png'
import golden_bitcoin from '../assests/golden_bitcoin.png'
import phoneLock from '../assests/hand-holding-phone-with-lock-screen-it 1.png'
import bitcoinBusiness from '../assests/bitcoin-business-as-part-economy 1.png'
import telegram from '../assests/telegram.png'
import x from '../assests/x.png'
import medium from '../assests/medium.png'
import mForgeToken from '../assests/mForgeToken.png'
import BNBToken from '../assests/bnbToken.png'
import exchange from '../assests/exchangeicon.png'
import swapExchange from '../assests/Group 427321911.webp'
import depositeToken from '../assests/depositeToken.png'
import token from '../assests/tokens.png'
import wallet from '../assests/wallet.png'
import stakeholder from '../assests/Stakeholder.png'
import decentralized from '../assests/Decentralized.png'
import commitment from '../assests/Commitment.png'
import tokenloc from '../assests/TokenLoc.png'
import voting from '../assests/VotingPower.png'
import liquidity from '../assests/LiquidityInc.png'
import flexible from '../assests/FlexibleWith.png'
import proposal from '../assests/Proposal.png'
import implementation from '../assests/Implementation.png'
import transparent from '../assests/Transparent.png'
import improvedTech from '../assests/improvedTech.png'
import increasedLiq from '../assests/increasedLiq.png'
import strongCom from '../assests/strongerCom.png'
import conntWallet from '../assests/conntWallet.png'
import verifyTokens from '../assests/verifyTokens.png'
import claimMforge from '../assests/claimMforge.png'
import confirmTransaction from '../assests/confirmTransaction.png'
import earnRewards from '../assests/earnRewards.png'
import home01 from '../assests/home01.png'
import home02 from '../assests/home02.png'
import home03 from '../assests/home03.png'
import home04 from '../assests/home04.png'
import home05 from '../assests/home05.png'
import referralimg from '../assests/referralimg.png'


const images={
    referralimg:referralimg,
    magnumLogo:magnumLogo,
    magnumForge:magnumForge,
    radioPointIcon:radioPointIcon,
    farmImage:farmImage,
    stackImage:stackImage,
    stackStepper:stackStepper,
    magnumDao:magnumDao,
    eLearn: eLearn,
    aiIcon: aiIcon,
    mgnumNote: mgnumNote,
    golden_bitcoin:golden_bitcoin,
    phoneLock:phoneLock,
    bitcoinBusiness:bitcoinBusiness,
    telegram:telegram,
    x:x,
    medium:medium,
    mForgeToken: mForgeToken,
    BNBToken: BNBToken,
    exchange: exchange,
    swapExchange:swapExchange,
    depositeToken:depositeToken,
    token:token,
    wallet:wallet,
    stakeholder:stakeholder,
    decentralized:decentralized,
    commitment:commitment,
    tokenloc:tokenloc,
    voting:voting,
    liquidity:liquidity,
    flexible:flexible,
    proposal:proposal,
    implementation:implementation,
    transparent:transparent,
    improvedTech:improvedTech,
    increasedLiq:increasedLiq,
    strongCom:strongCom,
    conntWallet:conntWallet,
    verifyTokens:verifyTokens,
    claimMforge:claimMforge,
    confirmTransaction:confirmTransaction,
    earnRewards:earnRewards,
    home01:home01,
    home02:home02,
    home03:home03,
    home04:home04,
    home05:home05,



}

export default images