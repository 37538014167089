import React, { useEffect, useState } from 'react'
import './Trade.css'
import { cardsData, cardColors, cardHeadColor, cardsData1, swapingCards } from '../Trade/TradeData'

export default function LiquidtyonDex() {


    const [readMore, setReadMore] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 1300);
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);


    const readMoreToggle = (index) => {
        setReadMore((prevState) => {
            const newReadMore = [...prevState];
            newReadMore[index] = !newReadMore[index];
            return newReadMore;
        })
    }

    return (
        <>
            <div className='container d-flex flex-column gap-5'>
                <div className="mforge_main">
                    <h1 className='swap-title text-center mt-5 mb-3'>How to Add Liquidity on a DEX</h1>
                </div>

                {/* Forge Cards */}

                <div className="forge-cards-main">
                    {
                        cardsData.map((data, index) => (
                            <div key={index} className={`forge_card`}>
                                <div className={`crd-cnt ${cardHeadColor[index]} `}>
                                    <div className='crd-nums'>{data.id}</div>
                                </div>
                                <div className={`frg_crd_cnt ${cardColors[index]}  ${(!isSmallScreen && index === 2) || (!isSmallScreen && index === 3) ? 'cards-liquidity-custom-height' : ''}`}>
                                    <div className="inside_card">
                                        <div>
                                            <img src={data.imageUrl} alt="wallet" loading='lazy' />
                                        </div>
                                        <div className='frg-content-box d-flex flex-column gap-2'>
                                            <h2 className='frg-crd-title m-0'>{data.title}</h2>
                                            {/* <p className='frg-cnt-para'>{data.description}</p> */}

                                            <p className='frg-cnt-para'>
                                                {readMore[index] || !isSmallScreen ? data.description : `${data.description.slice(0, 55)} ${data.description.length > 55 ? '...' : ''}`}
                                                {data.description.length > 55 && isSmallScreen && (
                                                    <span onClick={() => readMoreToggle(index)}>
                                                        {readMore[index] ? 'show less' : 'read more'}
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className='prov-liqdty mt-5'>
                    <h1 >Benefits of Providing Liquidity</h1>
                    <p>Earn rewards from trading fees, boost your token holdings, and contribute to the DEX ecosystem.</p>

                    <div className={`w-100 d-flex flex-wrap justify-content-center align-items-baseline gap-3 mt-4`}>
                        {
                            cardsData1.map((data, index) => (
                                <div key={index} className={`cards-liquidity d-flex flex-column gap-3 justify-content-center ${index < 2 && 'brdr-right'} `}>
                                    <h2>{data.title}</h2>
                                    <p>{data.description}</p>
                                </div>
                            ))
                        }
                    </div>

                </div>

                <div className="swapping-mforge my-4">
                    <h1>Swapping MFORGE for USDT</h1>
                    <div className="swapping-contnr">
                        {
                            swapingCards.map((data, index) => (
                                <div key={index} className="swapping-cards p-3 position-relative">
                                    <h2 className='my-4'>{data.title}</h2>
                                    <img src={data.img} alt="" width={60} loading='lazy' />
                                    <p>{data.description}</p>
                                    <img src={data.img2} alt="" loading='lazy' />
                                    <div className="nums position-absolute">{data.num}</div>
                                    <div className='wht-bdr position-absolute'></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
