import React from 'react'
import './Earn.css'

export default function YieldFarming() {
    return (
        <div className="yield-farming mt-sm-5 mt-3">
            <h1 className='earn-section-title'>What is Yield Farming?</h1>
            <div className="yeild-cards-container">
                <div className="yield-card-1">
                    <h4 className='yield-cards-title'>Liquidity Provision</h4>
                    <p className='yield-cards-para'>Provide liquidity to trading pairs and earn a share of the trading fees.</p>
                </div>
                <div className="yield-card-2">
                    <h4 className='yield-cards-title'>LP Token Staking </h4>
                    <p className='yield-cards-para'>Stake your LP tokens to help secure blockchain networks and earn rewards.</p>
                </div>
            </div>
        </div>
    )
}
