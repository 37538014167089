import React from 'react'
import radioPointIcon from '../../../assests/radioPointIcon.png'
import stackImage from '../../../assests/StackImage.png'

export default function Stack() {

    const para = [
        {
            text: 'Staking with MagnumMeta transcends mere token locking; it\'s about securing your investment in the future of education. Our staking mechanisms grant you the power to shape the direction of our ecosystem while earning rewards for your active involvement.'
        },
        {
            text: 'Within the MagnumMeta community, staking isn\'t a solitary act; it\'s a collective endeavor. By staking your tokens, you become an essential contributor to our ecosystem\'s growth and sustainability, all while enjoying the benefits of your commitment.'
        }
    ]

    return (
        <div className='w-100 row'>
            <div className="frm-cnt-lft col-12 col-md-6">
                {
                    para.map((item, index) => (
                        <div key={index} className='d-flex gap-1'>
                            <img style={{ width: '30px', height: '30px' }} src={radioPointIcon} alt="radioPointIcon" loading='lazy' />
                            <p className='m-0' key={index}>{item.text}</p>
                        </div>
                    ))
                }
            </div>
            <div className="frm-img-rgt col-12 col-md-6 p-0">
                <img src={stackImage} alt="farmimage" loading='lazy' />
            </div>
        </div>
    )
}
