import React from 'react'
import './Home.css'
// import images from '../../Utils/ImagesCollection'
import { FaArrowRightLong } from 'react-icons/fa6'
import { ButtonWrapper, ButtonRound } from '../../components/Buttons'
import HowItWork from './HowItWork/Index'
import Referal from './HowItWork/Referal'
import MugnumDAO from './MugnumDAO'
import MagnumEcosystem from './MagnumEcosystem'
import News from './News'
import Community from './Community'
import magnumForge from '../../assests/magnumMetaForge.webp'


export default function Home() {

    const metaForgeContent = [
        {
            para: 'Welcome to MetaForge (MFORGE), the core of the MagnumMeta revolution. MetaForge is the cornerstone of empowerment in our ecosystem, offering innovative features built on the robust BNB Chain Network. It empowers educators, learners, and stakeholders in every way possible.'
        },
        {
            para: 'MetaForge opens doors to limitless possibilities. Holders can earn incentives and boost their influence through staking. It empowers individuals to achieve their goals, whether they\'re learners seeking knowledge expansion or stakeholders shaping the future of education.'
        }
    ]

    return (
        <>
            <div className='home_main_container'>
                <div className='meta-forge-main'>
                    <div className="hm-mn-bx container">
                        <div className="meta-forge d-flex flex-wrap flex-column-reverse flex-md-row gap-4">
                            <div className="mt-fg-cnt">
                                <h1 className='section-title'>Harness the potential of MetaForge</h1>
                                {
                                    metaForgeContent.map((text, index) => (
                                        <p className='forge-text-para text-start text-sm-start' key={index}>{text.para}</p>
                                    ))
                                }
                                <div className='forge-grp-btn d-flex flex-wrap justify-content-center gap-4'>
                                    <a target='_blank' rel="noopener noreferrer" href="https://app.magnummeta.xyz/home">
                                        <ButtonWrapper className='rpsnbtn'>Launch App  <FaArrowRightLong /></ButtonWrapper>
                                    </a>
                                    <a className='hreftext' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
                                        <ButtonRound className='rpsnbtn'> Documentation<FaArrowRightLong /> </ButtonRound>
                                    </a>
                                </div>
                            </div>
                            <div className="mt-fg-img">
                                <img src={magnumForge} alt="magnum forge" />
                            </div>
                        </div>
                    </div>
                </div>
                <HowItWork />
                <Referal />
                <MugnumDAO />
                <MagnumEcosystem />
                <News />
                <Community />
            </div>
        </>
    )
}
