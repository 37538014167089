import { ButtonWrapper } from "../../components/Buttons";
import './Dao.css'
import Footer from "../../components/Footer/Footer";
import MagnumGov from "./MagnumGov";
import ProposalsOpen from "./ProposalsOpen";
import Voting from "./Voting";
import StakingMechanics from "./StakingMechanics";
import Execution from "./Execution";
import { FaArrowRightLong } from "react-icons/fa6";
import dao_home_image from '../../assests/dao_home_image.png'


export default function Dao() {

  return (
    <>
      <div className="container dao-container dao_unlock_home">
        <div className="row d-flex flex-md-row flex-column-reverse justify-content-center align-items-center mt-5">
          <div className="unlock-section col-md-6 d-flex flex-column justify-content-center gap-3">
            <h1 className="dao-section-title text-sm-start text-center">
              <span className="">Unlocking the Power of </span>
              <span className="">DAOs</span>
            </h1>
            <p className="dao-section-para text-sm-start text-center">Explore the transformative world of Decentralized Autonomous Organizations and how they are revolutionizing digital governance.</p>
            <a className='hrefReadMore' target='_blank' rel="noopener noreferrer" href="https://magnummeta.gitbook.io/docs">
              <ButtonWrapper className="rpsvdoabtn sndks" style={{ width: '230px' }}>Learn More <FaArrowRightLong /></ButtonWrapper>
            </a>
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img src={dao_home_image} className="daoImg" loading='lazy' />
          </div>
        </div>

        <MagnumGov />
        <ProposalsOpen />
        <Voting />
        <StakingMechanics />
        <Execution />

      </div>
    </>
  )
}
