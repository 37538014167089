// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ouotlet-layout {
}

.nav_section_lot {
  position: sticky;
  top: 0;
}
.background_color {
  background: linear-gradient(90deg, #fbf6ff 0%, #fff1ee 46.5%, #f1f6ff 100%);
  box-shadow: 0px 1px 3.3px 0px #00000040;
  z-index: 999;
}

@media screen and (max-width: 992px) {
  .background_color {
    background: none;
    box-shadow:unset;
    z-index: 1000;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout/Layout.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,gBAAgB;EAChB,MAAM;AACR;AACA;EACE,2EAA2E;EAC3E,uCAAuC;EACvC,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;EACf;AACF","sourcesContent":[".ouotlet-layout {\n}\n\n.nav_section_lot {\n  position: sticky;\n  top: 0;\n}\n.background_color {\n  background: linear-gradient(90deg, #fbf6ff 0%, #fff1ee 46.5%, #f1f6ff 100%);\n  box-shadow: 0px 1px 3.3px 0px #00000040;\n  z-index: 999;\n}\n\n@media screen and (max-width: 992px) {\n  .background_color {\n    background: none;\n    box-shadow:unset;\n    z-index: 1000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
