import React from 'react'
import './Dao.css'
import { executionCards } from './DaoData'

export default function Execution() {
    return (
        <>
            <div className="exe_prps d-flex flex-column justify-content-center align-items-center gap-sm-5 mt-sm-5 pt-sm-0 pt-5">
                <div className="bottomDao-left"></div>
                <h1 className='dao-section-title'>Execution of Approved Proposals</h1>
                <div className="exe-cards-conatain">
                    {
                        executionCards.map((data, index) => (
                            <div key={index} className="exe-cards position-relative" style={{ background: `linear-gradient(to right, ${data.color}` }}>
                                <div className="position-absolute exe-img">
                                    <img src={data.img} alt='card_image' width={150} loading='lazy' />
                                </div>
                                <div className="d-flex flex-column">
                                    <h4>{data.title}</h4>
                                    <p>{data.subtitle}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
