import React from 'react'
import './Dao.css'
import { votingCards, votingCards2 } from './DaoData'
// import images from '../../Utils/ImagesCollection'
import radioPointIcon from '../../assests/radioPointIcon.png'


export default function ProposalsOpen() {
    return (

        <div className="proposals-wrapper mt-4">
            <h1 className='dao-section-title'>Proposals Open for Voting</h1>

            <div className="d-flex flex-wrap justify-content-center mt-4 mb-3">
                {
                    votingCards.map((data, index) => (
                        <div key={index} className={`voting-cards ${index < 2 && 'voting-cards_brd_rht'} voting-cards_brd_btm`}>
                            <img src={radioPointIcon} alt="" width={30} height={30} loading='lazy' />
                            <h4>{data.title}</h4>
                        </div>
                    ))
                }
                {
                    votingCards2.map((data, index) => (
                        <div key={index} className={`voting-cards ${index < 2 && 'voting-cards_brd_rht'}`}>
                            <img src={radioPointIcon} alt="" width={30} height={30} loading='lazy' />
                            <h4>{data.title}</h4>
                        </div>
                    ))
                }
            </div>

            {/* <div className='row w-100'>
                <div className="p-0 col-sm-6 col-md-4 col-12 border-right-1" style={{borderRight:'1px solid red'}}>
                    <div className='voting-cards'>
                       <img src={images.radioPointIcon} alt="icon" width={30} height={30} />
                       <h4>Project Development Roadmap</h4>
                    </div>
                    <hr />
                    <div className='voting-cards'>
                       <img src={images.radioPointIcon} alt="icon" width={30} height={30} />
                       <h4>Ecosystem Partnerships and Integrations</h4>
                    </div>
                </div>
                <div className="p-0 col-sm-6 col-md-4 col-12 border-right-1" style={{borderRight:'1px solid red'}}>
                    <div className='voting-cards'>
                       <img src={images.radioPointIcon} alt="icon" width={30} height={30} />
                       <h4>Allocation of Treasury Funds</h4>
                    </div>
                    <hr />
                    <div className='voting-cards'>
                       <img src={images.radioPointIcon} alt="icon" width={30} height={30} />
                       <h4>Community Initiatives and Events</h4>
                    </div>
                </div>
                <div className="p-0 col-sm-6 col-md-4 col-12 flex-grow-1 border-right-1" style={{borderRight:'1px solid red'}}>
                    <div className='voting-cards'>
                       <img src={images.radioPointIcon} alt="icon" width={30} height={30} />
                       <h4>Uses for tokens acquired in buybacks</h4>
                    </div>
                    <hr />
                    <div className='voting-cards'>
                       <img src={images.radioPointIcon} alt="icon" width={30} height={30} />
                       <h4>Changes to Governance Processes</h4>
                    </div>
                </div>
            </div> */}

            <p className='text-center text-sm-start'>
                <span className="d-lg-block">The MagnumMeta DAO empowers its stakeholders to propose and vote on a wide range of decisions that
                </span>
                <span className="d-lg-block">shape the project's future. This ensures the community's collective vision is reflected in the DAO's evolution.
                </span>
            </p>
        </div>
    )
}
