// import images from '../../Utils/ImagesCollection'

import earnRewards from '../../assests/earnRewards.png'
import confirmTransaction from '../../assests/confirmTransaction.png'
import depositeToken from '../../assests/depositeToken.png'
import token from '../../assests/tokens.png'
import wallets from '../../assests/wallet.png'




import wallet from '../../assests/wallet1 (1).png'
import mforge from '../../assests/mforge.png'
import amout from '../../assests/amount.png'
import review from '../../assests/review.png'
import purpleCircle from '../../assests/Vector.png'
import orangeCircle from '../../assests/Vector (1).png'
import blueCircle from '../../assests/Vector (2).png'
import redCircle from '../../assests/Vector (3).png'



export const cardsData = [
    {
        id: 1,
        title: 'Connect Wallet',
        description: 'Link your cryptocurrency wallet to the DEX platform.',
        imageUrl: wallets
    },
    {
        id: 2,
        title: 'Select Token Pair',
        description: 'Choose the two tokens you want to provide liquidity for',
        imageUrl: token
    },
    {
        id: 3,
        title: 'Deposit Tokens',
        description: 'Add an equal value of the two tokens to the liquidity pool.',
        imageUrl: depositeToken
    },
    {
        id: 4,
        title: 'Confirm Transaction',
        description: 'Confirm the transaction in your connected wallet and wait for it to be processed. you\'ll receive LP (liquidity provider) tokens representing your share of the liquidity pool  Once complete,.',

       
        imageUrl: confirmTransaction
    },
    {
        id: 5,
        title: 'Earn Rewards',
        description: 'Receive trading fees proportional to your share of the pool.',
        imageUrl: earnRewards
    }
]

export const cardColors = ['frg-crd-indx-clrs1', 'frg-crd-indx-clrs2', 'frg-crd-indx-clrs3', 'frg-crd-indx-clrs4', 'frg-crd-indx-clrs5'];
export const cardHeadColor = ['cdt-cnt1', 'cdt-cnt2', 'cdt-cnt3', 'cdt-cnt4', 'cdt-cnt5'];

export const cardsData1 = [
    {
        id: 1,
        title: 'Earn Fees',
        description: 'By providing liquidity, you earn a portion of the trading fees generated by the MFORGE-USDT pool.',

    },
    {
        id: 2,
        title: 'Support the Ecosystem',
        description: 'Liquidity providers play a crucial role in ensuring liquidity and stability for the MFORGE and USDT trading pair on PancakeSwap.',

    },
    {
        id: 3,
        title: 'Yield Farming',
        description: 'LP tokens can be staked or used for yield farming to earn additional rewards or incentives from PancakeSwap',

    }
]

export const swapingCards = [
    {
        title: '1. Connect Wallet',
        img: wallet,
        description: 'Link your cryptocurrency wallet to the DEX platform.',
        img2: purpleCircle,
        num: 1,

    },
    {
        title: '2. Select MFORGE',
        img: mforge,
        description: 'Choose MFORGE as the token you want to swap',
        img2: orangeCircle,
        num: 2,

    },
    {
        title: '3. Enter Amount',
        img: amout,
        description: 'Specify the amount of MFORGE you want to swap for USDT.',
        img2: blueCircle,
        num: 3,

    },
    {
        title: '4. Review & Confirm',
        img: review,
        description: 'Verify the details and complete the swap transaction.',
        img2: redCircle,
        num: 4,

    },
]

