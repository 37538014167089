// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assests/main_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hom_wrraper{
  width: 100%;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  /* background-image: url('assests/background-image/optmzBgImg.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.loader{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

@media screen and (max-width: 600px) {
  .hom_wrraper{
    /* background: none; */
  }
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,WAAW;EACX,mDAA8C;EAC9C,sEAAsE;EACtE,4BAA4B;EAC5B,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;AAEzB;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","sourcesContent":["*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.hom_wrraper{\n  width: 100%;\n  background: url('assests/main_background.png');\n  /* background-image: url('assests/background-image/optmzBgImg.png'); */\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n}\n\n.loader{\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n}\n\n@media screen and (max-width: 600px) {\n  .hom_wrraper{\n    /* background: none; */\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
